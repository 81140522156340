<template>
 <v-app >
    <app-menu/>
      
    <div class="text-center">
      <v-dialog v-model="isLoad" persistent width="300">
        <v-card color="primary" dark >
          <v-card-text>
            cargando
            <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
          </v-card-text>
        </v-card> 
      </v-dialog>
    </div> 
    <div style="min-heigth:40px;">
      <!-- LUIS AGUILERA-->
      <!-- <app-slider v-if="verfiltro == false && page == 1" /> -->
      <app-slider/> 
    </div>
    <template class=""  >



<!-- Este es el inicio del content -->
      <v-row class=" justify-center " heigth v-if="isLoad==false">  
        
        

        <v-col cols="12" sm="9" class="" v-if="isLoad==false" >  
          <v-container style="background-color:white" class="justify-center" > 
          
            <v-chip class="ma-2"  v-for="categoria in filtrosCategorias" :key="categoria.m_product_category_id" 
              close color="primary" outlined @click:close="delete_categoria(categoria)">
              {{categoria.categoria}}
            </v-chip>

            <v-chip class="ma-2" v-for="sub_categoria in filtrosSubcategoria" :key="sub_categoria.m_product_classification_id" 
              close color="orange" label outlined  @click:close="delete_sub_categoria(sub_categoria.m_product_classification_id)">
              {{sub_categoria.sub_categoria}}
            </v-chip>
            <v-col>
              <v-row class="justify-center ">
                <v-col md="3">
                  <p class="text-h6 text-center pa-2" v-if="verfiltro == true && valorBuscado != '' && verClasificación == false" style="font-size: 3em;color :#909090">  
                    <v-btn text color="error" @click="deleteFilter"><v-icon>mdi-close-box-multiple</v-icon></v-btn><strong  style="">&nbsp; {{valorBuscado}}</strong>
                  </p>
                </v-col>
                <v-col md="7">
                  <v-card v-if="verClasificación == false" class="container text-center" max-width="200" style="color:black" elevation="0"> 
                    <v-row class="justify-center mx-auto">
                      <v-col md="3">
                        <v-avatar
                          color="pink"
                          size="36"
                        >
                          <span class="white--text text-h6">{{productos.length}}</span>
                        </v-avatar>   
                      </v-col>
                      <v-col md="9" class="justify-center">
                        <div class="text-h6" style="margin:0 auto;"> &nbsp; Resultados</div> 
                      </v-col>
                    </v-row>
                    <!-- <strong></strong>  -->
                    <!-- <v-divider color="cyan"></v-divider> -->
                    </v-card>
                </v-col>
                <v-col cols md="2" class="text-center" v-if="verClasificación == false">
                  <v-select
                      
                      :items="[8,16,24,32,40]"
                      outlined
                      label="Número de Productos a Mostrar"
                      dense
                      @change="paginator()"
                      v-model="totalPage" 
                  ></v-select>            
                </v-col>
                <v-col cols="12" md="3">
                  <v-row class="pa-2">
                    <v-text-field  label="Buscar producto" @keyup.enter.native="search(filter), verProductos = true, verClasificación = false, principal = false" v-model="filter" dense  hide-details rounded solo-inverted ></v-text-field>  
                    <v-btn icon  @click="search(filter), verProductos = true, verClasificación = false,principal = false"><v-icon color="pink">mdi-magnify</v-icon> </v-btn>  
                  </v-row>
                </v-col>
                <v-col cols md="3" v-if="verClasificación == true && principal == false">
                  <v-row class="pa-2"> 
                    <v-text-field color="pink" v-model="buscar" label="Buscar clasificación" rounded solo-inverted dense ></v-text-field>
                    <v-btn icon><v-icon color="pink">mdi-magnify</v-icon> </v-btn>
                  </v-row>
                </v-col>
                 <v-col cols md="3" class="text-center" v-if="verClasificación == true">
                  <v-select
                      :disabled="principal== true"
                      style="width: 230px; margin:0 auto; "
                      color="pink"
                      :items="[8,16,24,32]"
                      outlined
                      item-color="pink"
                      label="Número de Clasificaciones a Mostrar"
                      dense
                      @change="paginatorClasificacion()"
                      v-model="totalPage2" 
                  ></v-select>            
                </v-col>
                
              </v-row>
            </v-col>
            
          </v-container> 
          <v-container v-if="principal ">
            <v-row class="justify-center">
              <v-card  v-for="escencia in classGeneral" :key="escencia.m_class_general_id" style="margin-top:0.2em; margin:10px" width="600" elevation="10" @click="request_esencia2(escencia.m_class_general_id)"> 
                <v-img width="650" link :src="`${ubicacionCategoriaGeneral}${escencia.description}`"></v-img>
                <v-img v-if="escencia.description == null"  width="650" link :src="`${ubicacionCategoriaGeneral}NO-DISPONIBLE.jpeg`"></v-img>
                <div class="text-center btn button" style="color:orange;font-size:20px;cursor:pointer;" @click="request_esencia2(escencia.m_class_general_id)">{{escencia.value}}</div>
                
              </v-card>
            </v-row>
          </v-container>
          <v-container v-if="verClasificación && !isMobile() && clasificacionProducto==true">
            <v-row class="justify-center">
              <!-- <v-card  style="margin-top:0.2em; margin:10px" class="" width="650" elevation="5"> -->
              <v-card  style="margin-top:0.2em; margin:10px" class="" width="450" elevation="5"> 
                <!-- <v-img width="650" link src="../../public/Home/amoresens14.jpg" @change="applyFilter()"  
                   @click="paginaPrincipal()"></v-img> -->
                <v-img width="450" link src="../../public/Home/Home.jpg" @change="applyFilter()"  
                   @click="paginaPrincipal()"></v-img>
                <v-checkbox :class="['original-checked']" dense style="margin:0 auto; color:white;" @change="applyFilter()"  
                   @click="paginaPrincipal()">
                  <template v-slot:label >
                    <span  id="checkboxLabel">TODOS LOS PRODUCTOS</span>
                  </template>
                </v-checkbox>
              </v-card>
              
              <!-- <v-card type="checkbox" v-for="escencia in filtrador" :key="escencia.value" style="margin-top:0.2em; margin:10px"  width="650" elevation="5" > -->
              <v-card type="checkbox" v-for="escencia in filtrador" :key="escencia.value" style="margin-top:0.2em; margin:10px"  width="450" elevation="5" > 
                <v-img width="450" link :src="`${ubicacionSubCategorias}${escencia.description}`"></v-img>
                <v-img v-if="escencia.description == null"  width="650" link :src="`${ubicacionCategoriaGeneral}NO-DISPONIBLE.jpeg`"></v-img>
                <!-- <v-img width="650" link src="../../public/Home/Velas.jpeg"></v-img> -->
                <v-checkbox :class="['original-checked']" dense   style="margin:0 auto; color:white;" @change="applyFilter()" v-model="escencia.isview" 
                     @click="verProductos = true, verClasificación = false">
                  <template v-slot:label >
                    <span id="checkboxLabel">{{escencia.value}}</span>
                  </template>
                </v-checkbox>
              </v-card>
            </v-row>
            <br>
            <div class="text-center" v-if="verClasificación == true">
              <v-pagination 
                v-on:input="paginatorClasificacion" 
                v-model="page" 
                :length="lengthPaginator2"  
                circle
                color="blue"
                :total-visible="5"
                ></v-pagination>
            </div> 
          </v-container>
          <v-container v-if="verClasificación && isMobile() && clasificacionProducto==true">
            <v-row class="justify-center">
              <v-card  style="margin-top:0.2em; margin:10px" class="" width="450" elevation="5"> 
                <v-img width="450" link src="../../public/Home/Home.jpg" @change="applyFilter()"  
                   @click="paginaPrincipal()"></v-img>
                <v-checkbox :class="['original-checked2']" dense style="margin:0 auto; color:white;" @change="applyFilter()"  
                   @click="paginaPrincipal()">
                  <template v-slot:label >
                    <span  id="checkboxLabel">TODOS LOS PRODUCTOS</span>
                  </template>
                </v-checkbox>
              </v-card>
              
              <v-card type="checkbox" v-for="escencia in filtrador" :key="escencia.value" style="margin-top:0.2em; margin:10px"  width="450" elevation="5" > 
                <!-- <v-img v-if="escencia.description != 'jpeg'"  width="650" link :src="`${path}NO-DISPONIBLE.jpeg`"></v-img> -->
                <v-img width="650" link :src="`${ubicacionSubCategorias}${escencia.description}`"></v-img>
                <v-img  v-if="escencia.description == null"  width="650" link :src="`${ubicacionCategoriaGeneral}NO-DISPONIBLE.jpeg`"></v-img>
                <v-checkbox :class="['original-checked2']" dense   style="margin:0 auto; color:white;" @change="applyFilter()" v-model="escencia.isview" 
                    :label="escencia.value" @click="verProductos = true, verClasificación = false">
                  <template v-slot:label >
                    <span id="checkboxLabel">{{escencia.value}}</span>
                  </template>
                </v-checkbox>
              </v-card>
            </v-row>
            <br>
            <div class="text-center" v-if="verClasificación == true">
              <v-pagination 
                v-on:input="paginatorClasificacion" 
                v-model="page" 
                :length="lengthPaginator2"  
                circle
                color="blue"
                :total-visible="5"
                ></v-pagination>
            </div> 
          </v-container>

          <!-- PRODUCTOS -->
          <v-container v-if="verProductos " class="" style="background-color:white;">
            <v-row  class="mb-6 justify-center" style="margin:0 auto;" > 
              <v-col md="12">
                <v-btn text color="pink" @click="regresar()"><v-icon>mdi-arrow-left-bold</v-icon>Regresar</v-btn>
                <v-divider></v-divider>
                <br>
              </v-col>
              <v-col md="3"  v-for="producto in productosPaginator" :key="producto.value"> 
                <v-hover>
                  <template v-slot:default="{ hover }" >
                      <a style="text-decoration: none;" :href="'/shop/Product/' + producto.value + '/home/' + page"> 
                        <v-card style=" border-bottom: 2px solid red; margin:0 auto;" :elevation="hover ? 24 : 2"  class="justify-center" width="260" height="auto" >
                          
                          <center>   
                              <v-img :src="`${ubicacionImagenProducto}${producto.value}.jpg`" :lazy-src="`../../public/noImg.png`"
                                      aspect-ratio="1" class="grey lighten-2" width="220" height="170">  
                                <v-row justify="end" class="fill-height ma-0" v-if="producto.monto_descuento != null">
                                  <v-avatar color="#02DE7D" size="70" >
                                    <span class="white--text">
                                        {{producto.descuento_name}}
                                        <div class="white--text" style="font-size: 0.7em;">
                                        descuento
                                        </div>
                                    </span> 
                                  </v-avatar>
                                </v-row>
                                <template v-slot:placeholder>
                                  <v-row class="fill-height ma-0" align="center" justify="center">
                                    <v-progress-circular indeterminate color="grey lighten-5" ></v-progress-circular>
                                  </v-row>
                                </template> 
                              </v-img>  
                            <div class="text-decoration-line-through" style="color: green;margin-bottom:-25px" v-if="producto.monto_descuento != null">
                                Antes {{formatMXN((producto.costo_original))}}
                            </div>
                          </center>  
                          <v-container class="mx-auto" style=""> 
                            <v-card class="container" elevation="0">  
                              <div>{{producto.name.substring(0,64)}}</div>  
                              <div class="my-3 subtitle-2" >{{cut_text(producto.categoria,9)}} : {{cut_text(producto.sub_categoria,11)}}</div> 
                              <div class="my-3 subtitle-2">Presentación: {{producto.presentacion}}</div> 
                              <div class="my-3 subtitle-2">Intensidad : {{producto.intencidad}}</div>
                              <div class="my-3 subtitle-2">Código : {{producto.value}}</div>
                            </v-card>
                          </v-container>
                          <v-divider class="mx-4">Costos</v-divider>
                          <v-card-actions> 
                            <v-chip class="ma-1"  color="pink" label text-color="white"> 
                              <div v-if="producto.monto_descuento != null">
                                Ahora {{ formatMXN(producto.l0) }}
                              </div>
                              <div v-else>
                                {{ formatMXN(producto.l0) }}
                              </div>
                            </v-chip>  
                            <p class="font-weight-light my-2" style="font-size: 0.9em;">
                              {{ producto.mex_quantytotal }} {{producto.mex_quantytotal==1?' Disponible':' Disponibles'}}
                            </p>
                          </v-card-actions> 
                        </v-card> 
                      </a> 
                  </template>
                </v-hover> 
              </v-col> 
            </v-row>   
            <div class="text-center" v-if="verProductos">
              <v-pagination 
                v-on:input="paginator" 
                v-model="page" 
                :length="lengthPaginator"  
                circle
                :total-visible="7"
                ></v-pagination>
            </div>  
            
          </v-container>
          <br> 
        </v-col>

        <v-col cols="9" sm="2" v-if="isMobile() && isLoad==false && verPromos == true" >
              <v-card elevation="15" class="container">
                <center><v-btn fab x-small color="pink" @click="verPromos=false" ><v-icon>mdi-close</v-icon></v-btn></center>
                  <v-alert class="my-2 text-center">
                     <h2 style="color:orange;">
                      <strong>¡ NUEVOS PRODUCTOS !</strong>
                    </h2> 
                  </v-alert>
                <v-virtual-scroll item-height="350" height="1100" :items="productoNuevo">
                      <template v-slot:default="{item}">
                        <v-alert width="100%" color="cyan" border="left"  elevation="1" colored-border> 
                          <a style="text-decoration: none;" :href="'/shop/Product/' + item.value + '/home/' + page"> 
                            <div>
                              <center>   
                                <v-img width="60%" :src="`${ubicacionImagenProducto}${item.value}.jpg`" 
                                      :lazy-src="`../../public/noImg.png`"
                                        aspect-ratio="1" class="grey lighten-2"> 
                                  <template v-slot:placeholder>
                                    <v-row class="fill-height ma-0" align="center" justify="center">
                                      <v-progress-circular indeterminate color="pink" ></v-progress-circular>
                                    </v-row>
                                  </template> 
                                </v-img>
                              </center>
                              <v-card-text>
                                <div class="my-3 subtitle-2">{{item.name.substring(0,83)}}</div> 
                              </v-card-text>  
                            </div>
                          </a>
                        </v-alert>
                      </template>
                  </v-virtual-scroll>
              </v-card> 
        </v-col> 
        <v-col cols="12" sm="2" v-if="!isMobile() && isLoad==false && verPromos == true" >
                <v-card  class="my-5 container" style="margin-right:10px" elevation="15">
                  <v-btn fab x-small color="pink" @click="verPromos=false" ><v-icon>mdi-close</v-icon></v-btn>
                  <v-alert class="my-2 text-center">
                     <h2 style="color:orange;">
                      <strong>¡ NUEVOS PRODUCTOS !</strong>
                    </h2> 
                  </v-alert>
                  <v-virtual-scroll class="mx-auto text-center justify-center" item-height="350" height="1100" :items="productoNuevo">
                      <template v-slot:default="{item}">
                        <center>
                          <v-alert width="100%" max-width="197" color="cyan" border="left"  elevation="3" colored-border> 
                              <a style="text-decoration: none;" :href="'/shop/Product/' + item.value + '/home/' + page"> 
                                <div>
                                  <center>   
                                    <v-img width="80%" :src="`${ubicacionImagenProducto}${item.value}.jpg`" 
                                          :lazy-src="`../../public/noImg.png`"
                                            aspect-ratio="1" class="grey lighten-2"> 
                                      <template v-slot:placeholder>
                                        <v-row class="fill-height ma-0" align="center" justify="center">
                                          <v-progress-circular indeterminate color="pink" ></v-progress-circular>
                                        </v-row>
                                      </template> 
                                    </v-img>
                                  </center>
                                  <v-card-text>
                                    <div  v-snip="1" class="my-2 subtitle-2">{{item.name}}</div> 
                                  </v-card-text>  
                                </div>
                              </a>
                          </v-alert>
                        </center>
                      </template>
                  </v-virtual-scroll>
              </v-card> 
          </v-col>
          <!-- <v-col cols="12" sm="1"></v-col> -->
        </v-row>

        <v-container v-else>
          <v-skeleton-loader 
            type="list-item-avatar, divider, list-item-three-line, card-heading, image, actions"
          ></v-skeleton-loader>
            <v-skeleton-loader 
            type="list-item-avatar, divider, list-item-three-line, card-heading, image, actions"
          ></v-skeleton-loader>
            <v-skeleton-loader 
            type="list-item-avatar, divider, list-item-three-line, card-heading, image, actions"
          ></v-skeleton-loader>
        </v-container>
        <v-dialog v-model="actualizar" width="500" persistent>
          <v-card  prepend-icon="mdi-note-text-outline"> 
            <v-card color="teal accent-4" height="35" dark elevation="8">
              <div class="text-center headline">¡ HOLA  &nbsp; {{user.cpname}} !</div>
            </v-card>
            <!-- <v-card-title>¡ HOLA  &nbsp; {{user.cpname}} !</v-card-title> -->
            <v-divider></v-divider>
            
            <v-form>
              <v-card-text>
                <v-row>
                  <div class="overline container">
                    POR FAVOR ACTUALIZA TU R&Eacute;GIMEN TRIBUTARIO.
                  </div>
                  <v-col>
                    <v-form ref="actualizarRT" >
                      <v-select
                      v-model="nuevoRegimen" :items="regimenTributario"
                      label = "Régimen Tributario"
                      item-value="c_taxgroup_id"
                      item-text="name"
                      style="width:400px"
                      :rules="[(v)=> !!v || 'Selecciona un Régimen']"
                      color="orange"
                      ></v-select>
                    </v-form>
                  </v-col>
                </v-row>
                <v-row>
                  <div class="container overline" style="color:teal;">{{msg}} </div>
                </v-row>
              </v-card-text>
              <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="overline" text  dark color="teal accent-4" @click="actualizarRegimen(user.c_bpartner_id)"> actualizar <v-icon>mdi-note-text-outline</v-icon></v-btn>
              <v-btn class="overline"  dark  color="red" @click="actualizar = false">cerrar</v-btn>
            </v-card-actions>
            </v-form>
          </v-card>
            
        </v-dialog>
    </template> 
  </v-app> 
</template> 
<style>
  .checkbox-dense-margin-top{
    color:red  
  }
  .v-card .v-checkbox  {
        font-size: 13px !important;
        text-align: center !important;
    }
  #checkboxLabel {
   color: orange;
   font-size: 18px;
   margin:0 auto;
   
   /* font-family: 'Courier New', Courier, monospace; */
  }
  #checkbox{
    color: red;
    font-size:35px;
  }
  .original-checked i:not(.gray--text) {
    color: transparent;
    justify-content: center;
    font-size:270px ;
    /* position:relative; */
    display: flex;
    flex-direction: row; 
    flex-wrap: wrap;
    justify-content: flex-start;
    top: -0.9em;
    bottom: 0;
    margin-left: 0.4em;

}
.original-checked2 i:not(.gray--text) {
    color: transparent;
    justify-content: center;
    font-size:260px ;
    display: flex;
    flex-direction: row; 
    flex-wrap: wrap;
    justify-content: flex-start;
    top: -0.9em;
    bottom: 0;
    margin-left: 0.4em;
}

</style> 
 
<script>
import AppSlider from '../components/Slider'; 
import config from '../json/config.json'
import axios from 'axios'; 
import AppMenu from '../components/Menu.vue';

export default {
  name: "Productos", 
  props: ['pagina'], 
  data() {
    return {
      ubicacionImagenProducto:'https://amoresens.com.mx/distribuidor/productos/',
      ubicacionCategoriaGeneral:'https://amoresens.com.mx/distribuidor/categorias_generalv2/',
      ubicacionSubCategorias:'https://amoresens.com.mx/distribuidor/categorias_subcategoriasv2/',
      buscar:'',
      verClasificación:true,
      verProductos:false,
      verPromos : true,
      productosOferta : [],
      ordenMenorP : false,
      ordenMayorP : false,
      ordenMasVendido : false, 
      orden : 'Descripción',
      isfilterAndalucia:true,
      isfilterLD:true,
      onlystock : false,
      page: 1,
      filter : "",

      min: 0,
      max: 5000,
      range: [0, 5000],
      maxProd : 0,
      minProd : 0,
      // isMobile : null,
      sceemFiltro : false,
      typeScreem:null,
      productos : [],
      productosPaginator : [],
      escenciaPaginator:[],
      lengthPaginator:0,
      lengthPaginator2:0,
      totalPage:8,
      totalPage2:8,
      isLogged : false,
      user : [],
      isLoad : false,
      valorBuscado : "",
      verfiltro : false,
      productosFiltrados : [],
      atttibutes : {marcas:[],categorias:[],intencidades:[],presentaciones:[]},
      filtrosCategorias : [],
      filtrosSubcategoria : [],
      escenciaCollection:[],
      escenciaCollectionmenu:[],
      show_escencia : false,
      show_intencidad : false,
      show_marca : false,
      rating:5,
      //prueba
      element:{},
      element2:[],
      element3:{},
      ocultar:false,
      clasificacionProducto:false,
      classGeneral:{},
      principal:true,
      productoNuevo:[],
      actualizar:false,
      regimenTributario:[],
      nuevoRegimen:'',
      msg:''
    }; 
  }, 
  components: { 
    'app-slider': AppSlider,
    'app-menu': AppMenu, 
  },
  async mounted(){
    this.clasificacionGenerales();
    this.ProductosNuevos();
    this.regimenTribu();
  }, 
  async created() {
    this.clean_localStorage()
    try{
      if (parseInt(this.pagina) > 0) {
        this.page = parseInt(this.pagina);
      }else{
        this.page = 1;
      }
    }catch (ex){
      this.page = 1;
    }
    this.isLoad = true; 
    await this.getattibutes();
    await this.validaLogin();
    // await this.allProduct(); 
    
    // if(this.recoverParams()){
    //   this.applyFilter();
    // }
    
    // await this.applyFilter();
    // this.getExtremos();

    if (localStorage.getItem("datos") === null) {
        await this.applyFilter();
        this.getExtremos();
        // console.log("entro");

    }else{

        await this.allProductv2();
        this.getExtremos();
        // console.log("entro2");
        await this.recuperarMarcaAtributo();
        await this.recuperarIntensidadAtributo();
        await this.recuperarCategoriaAtributo();
        await this.recuperarSubCategoriaAtributo();
        //console.log(this.atttibutes.intencidades);

    }
    
    this.productosOferta = [];
    for (let index = 0; index < this.productos.length; index++) {
      let element = this.productos[index];  
      if (element.value == 'P15UN0978' || element.value == 'P15UN0979' || element.value == 'P15UN1122' || element.value == 'P15UN1123') {
          this.productosOferta.push(element);
      } 
    } 
    await this.paginator(); 
    await this.paginatorClasificacion();
    this.verfiltro = false;
    this.page = 1;
    this.isLoad = false;  
  } 
  ,methods: {

      clean_localStorage(){ 
        //localStorage.removeItem('datos');
        //localStorage.clear();

        window.localStorage.clear(); //clear all localstorage
        window.localStorage.removeItem("datos"); //remove one item
 

        this.filter = '';
        this.onlystock = false;
        //this.range =  '';
        //this.range = [this.min, this.max];
        this.ordenMenorP = false;
        this.ordenMayorP = false;
        this.ordenMasVendido = false;
        this.atttibutes.marcas = [];
        this.atttibutes.intencidades = [];
        this.filtrosCategorias = [];
        this.filtrosSubcategoria = [];        
        //this.getattibutes();
        
        this.applyFilter();
        this.getExtremos();
        this.deleteFilter();

      //  location.reload();

    },   

    cut_text(text,i){
      // producto.sub_categoria
      let resturn_text = "";
      if (text == null) {
        return "";
      }
      try {
        resturn_text = text.substring(0,i);
      } catch (error) {
        console.log(error);
      }
      return resturn_text;
    },
    add_category_filter(categoria){ 
      if (categoria.sub_categorias.length == 0) {
        this.add_category(categoria,true);
      } 
    },
    delete_sub_categoria(m_product_classification_id){ 
      if (this.comprobarExistenciaInArray(this.filtrosSubcategoria,m_product_classification_id,'m_product_classification_id')){
        let filtros = [];
        for (let index = 0; index < this.filtrosSubcategoria.length; index++) { 
          if (this.filtrosSubcategoria[index].m_product_classification_id != m_product_classification_id) {
            filtros.push(this.filtrosSubcategoria[index]); 
          }
        }
        this.filtrosSubcategoria = filtros;
        this.applyFilter();
      }
    },
    delete_categoria(categoria){ 
      if (this.comprobarExistenciaInArray(this.filtrosCategorias,categoria.m_product_category_id,'m_product_category_id')){
        for (let index = 0; index < categoria.sub_categorias.length; index++) {
          this.delete_sub_categoria(categoria.sub_categorias[index].m_product_classification_id);
        }
        let filtros = []; 
        for (let index = 0; index < this.filtrosCategorias.length; index++) { 
          if (this.filtrosCategorias[index].m_product_category_id != categoria.m_product_category_id) {
            filtros.push(this.filtrosCategorias[index]); 
          }
        }  
        this.filtrosCategorias = filtros;
        this.applyFilter();
      } 
    },
    add_category(categoria,apllifilter){

      if (!this.comprobarExistenciaInArray(
        this.filtrosCategorias
        ,categoria.m_product_category_id
        ,'m_product_category_id')) {
        this.filtrosCategorias.push(categoria); 
        if (apllifilter) {
          this.applyFilter(); 
        }
      }
    },
    add_sub_category(sub_categoria,categoria){  
      this.add_category(categoria,false);
      if (!this.comprobarExistenciaInArray(
        this.filtrosSubcategoria
      ,sub_categoria.m_product_classification_id
      ,'m_product_classification_id')){
        this.filtrosSubcategoria.push(sub_categoria);
        this.applyFilter();
      }
    },
    comprobarExistenciaInArray(array,value,field){
      let respuesta = false;
      for (let index = 0; index < array.length; index++) {
        const element = array[index]; 
        if (element[field] == value) {
          respuesta = true;
        }
      } 
      return respuesta;
    },
    async getattibutes(){
      this.requestattributes_marcas();
      this.requestattributes_intencidad();
      this.requestattributes_presentacion();  
      this.requestattributes_categoria();  
      // this.request_esencia();
    }, 
    async requestattributes(types,m_p_cat_id){
      let resource = [];
      resource = await axios.get(config.apiAmoresens + "/productos/getattributes", 
      {'headers': { 'token': this.$cookie.get('token') },params:{type:types,m_product_category_id:m_p_cat_id}})
      .then(res=>{return res.data;}) 
      .catch(err=>{return err;});    
      if (resource.status == "success") {
        resource = resource.data;
      }else{
        resource = [];
      }
      return resource;
    },
    async requestattributes_marcas(){
      let types = 'marcas';
      let resource = [];
      resource = await axios.get(config.apiAmoresens + "/productos/getattributes", 
      {'headers': { 'token': this.$cookie.get('token') },params:{type:types,m_product_category_id:0}})
      .then(res=>{return res.data;}) 
      .catch(err=>{return err;});    
      if (resource.status == "success") {
        this.atttibutes.marcas = resource.data;
      }else{
        this.atttibutes.marcas = [];
      } 
    },
    async requestattributes_categoria(){
      let types = 'categoria';
      let resource = [];
      resource = await axios.get(config.apiAmoresens + "/productos/getattributes", 
      {'headers': { 'token': this.$cookie.get('token') },params:{type:types,m_product_category_id:0}})
      .then(res=>{return res.data;}) 
      .catch(err=>{return err;});    
      if (resource.status == "success") {
        this.atttibutes.categorias = resource.data;
        for (let index = 0; index < this.atttibutes.categorias.length; index++) {
          let res = await this.requestattributes('sub_categoria' 
          ,this.atttibutes.categorias[index].m_product_category_id)
          this.atttibutes.categorias[index].sub_categorias = res;
        }
      }else{
        this.atttibutes.categorias = [];
      } 
    },
    async requestattributes_intencidad(){
      let types = 'intencidad';
      let resource = [];
      resource = await axios.get(config.apiAmoresens + "/productos/getattributes", 
      {'headers': { 'token': this.$cookie.get('token') },params:{type:types,m_product_category_id:0}})
      .then(res=>{return res.data;}) 
      .catch(err=>{return err;});    
      if (resource.status == "success") {
        this.atttibutes.intencidades = resource.data;
      }else{
        this.atttibutes.intencidades = [];
      } 
    },
    

    async requestattributes_presentacion(){
      let types = 'presentacion';
      let resource = [];
      resource = await axios.get(config.apiAmoresens + "/productos/getattributes", 
      {'headers': { 'token': this.$cookie.get('token') },params:{type:types,m_product_category_id:0}})
      .then(res=>{return res.data;}) 
      .catch(err=>{return err;});    
      if (resource.status == "success") {
        this.atttibutes.presentaciones = resource.data;
      }else{
        this.atttibutes.presentaciones = [];
      } 
    },

    async request_esencia(){
      let resource = [];
      resource = await axios.get(config.apiAmoresens + "/productos/getesencia", 
      {'headers': { 'token': this.$cookie.get('token') },params:{}})
      .then(res=>{return res.data;})
      .catch(err=>{return err;});
      if (resource.status == "success") {
        this.escenciaCollection = resource.data;
        // console.log(this.escenciaCollection);
      }else{
        this.escenciaCollection = [];
      } 
    },
    async request_esencia2(m_class_general_id){
      let id = m_class_general_id;      
      let resource = [];
      resource = await axios.get(config.apiAmoresens + "/productos/getesencia2", 
      {'headers': { 'token': this.$cookie.get('token') },params:{id:id}})
      .then(res=>{return res.data;})
      .catch(err=>{return err;});
      if (resource.status == "success") {
        this.escenciaCollection = resource.data;
        this.principal=false;
        this.clasificacionProducto = true;
      }else{
        this.escenciaCollection = [];
      } 
      await this.paginatorClasificacion();
    },
    getExtremos(){
      for (let index = 0; index < this.productos.length; index++) {
        const element = this.productos[index]; 
        if (index == 0) {
            this.min = element.l0;
            this.max = element.l0; 
        }
        if (parseFloat(this.max) < parseFloat(element.l0)){
          this.max = parseInt(element.l0);
        }
        if (parseFloat(element.l0) < parseFloat(this.min))
        { 
          this.min = parseInt(element.l0);
        }
      }  
      this.range = [this.min, this.max]; 
    },

    async applyFilter(){
      this.ordenMenorP = false;
      this.ordenMayorP = false;
      this.ordenMasVendido = false;
      switch (this.orden) {
        case 'Menor precio':
          this.ordenMenorP = true;
          break;
        case 'Mayor precio':
          this.ordenMayorP = true;
          break;
        case 'Más vendido':
          this.ordenMasVendido = true;
          break;    
      }
      this.isLoad = true;
      await this.allProduct();
      this.page = 1;
      await this.paginator();
      this.verfiltro = true;
      this.sceemFiltro = false;
      this.isLoad = false;
    },
    movementPriceFilter(){
      this.applyFilter();
    },
    async filterOnlystock(){
      this.applyFilter();
    },
    showFilter(value){ 
      this.typeScreem = value;
      this.sceemFiltro = true;
    },
    isMobile(){
      if( navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i)){
          return true;
        }else{
          return false;
        }
    },
    async deleteFilter(){
      this.page = 1;
      this.isLoad = true;  
      this.filter = "";
      this.valorBuscado = "";
      this.verfiltro = false;
      await this.allProduct();
      await this.paginator();
      this.isLoad = false;  
    },
    async search(){   
      this.isLoad = true;  
      this.valorBuscado = this.filter.toUpperCase();
      await this.allProduct();
      this.page = 1;
      await this.paginator();
      this.verfiltro = true;
      this.isLoad = false;  
    },
    async validaLogin(){
         this.user = await axios.get(config.apiAmoresens + "/user/userByToken", 
        {'headers': { 'token': this.$cookie.get('token') }})
        .then(res=>{return res.data;})
        .catch(err=>{return err;});    
        if (this.user.status == "success") {
          this.user = this.user.user; 
          // console.log(this.user); 
          this.isLogged = true;
          if(this.user.taxid != "XAXX010101000" && this.user.c_taxgroup_id === null){
            this.actualizar = true;
          }else{
            this.actualizar = false;
          }
        }else if(this.user.status == "unauthorized"){ 
          this.isLogged = false;
        }  
    }
    ,agrupacion_isview(array,fieldconcat,isFilter){
      let cadenaRetorno = "";
      for (let i = 0; i < array.length; i++) {
        const element = array[i];
        if (isFilter) {
          if (element.isview) {
            cadenaRetorno += element[fieldconcat] + ",";
          }
        }else{
          cadenaRetorno += element[fieldconcat] + ",";
        }
      }
      cadenaRetorno += "#";
      cadenaRetorno = cadenaRetorno.replace(',#','');
      return cadenaRetorno;
    }

    ,async allProduct(){
      this.productos = [];
      this.productosPaginator = [];

      let empresasValidas = this.agrupacion_isview(this.atttibutes.marcas,'m_product_group_id',true);
      let intencidadesValidas = this.agrupacion_isview(this.atttibutes.intencidades,'m_class_intensity_id',true);
   
      let categorias_group_v = this.agrupacion_isview(this.filtrosCategorias,'m_product_category_id',false);
      let sub_categorias_group_v = this.agrupacion_isview(this.filtrosSubcategoria,'m_product_classification_id',false);

      let escencia_group = this.agrupacion_isview(this.escenciaCollection,'m_class_essences_id',true);
      // let escencia_group2 = this.agrupacion_isview(this.escenciaCollectionmenu,'m_class_essences_id',true);
      let uri = config.apiAmoresens + "/productos/all";


      // console.log("A1");
      // console.log(this.atttibutes.intencidades);

      const paramsv2 = {
            filter: this.filter
            ,onliStock : this.onlystock
            ,range : this.range
            ,marcasfiltradas : empresasValidas
            ,ordenMenorP : this.ordenMenorP
            ,ordenMayorP : this.ordenMayorP
            ,ordenMasVendido : this.ordenMasVendido  
            ,intencidadesfiltradas : intencidadesValidas
            ,categorias_group : categorias_group_v
            ,sub_categorias_group : sub_categorias_group_v
            ,escencia_group : escencia_group
            // ,escencia_group2 : escencia_group2
        };


      this.productos = await axios.get(uri
      ,{
        headers: { 'token': this.$cookie.get('token') },
        params: paramsv2
      

      }).then(function (response) { 
        if (response.data.status == "success") {
          return response.data.data;
        } else {
          // console.log(response.data.data);
          return [];
        }
      }).catch(function (response){ 
        console.log(response);
        return [];
      });

        // Guardo el objeto como un string
      localStorage.setItem('datos', JSON.stringify(paramsv2));
      this.lengthPaginator = Math.ceil(this.productos.length/this.totalPage); 
    },


    async recuperarMarcaAtributo(){

      let memoriaCache = JSON.parse( localStorage.getItem('datos') );

      let coleccionMarcas = this.atttibutes.marcas;
      let boleano_marcas = true;

      // console.log('GENERAR ARRAYS');
      const filtroMarcas = memoriaCache.marcasfiltradas;
      let dato_split = filtroMarcas.split(',') // [ 'free', 'code', 'camp' ]
      // console.log(dato_split);

      // console.log(' RECORRER ARRAYS GENERADO');
      let nuevaColeccionMarcas = dato_split;
      for (var i=0; i<nuevaColeccionMarcas.length; i++) {
              // console.log('Datos Obtenidos Marcav1');
              // console.log(nuevaColeccionMarcas[i]);


      // console.log('INICIA MODIFICACION ARRAY');
        coleccionMarcas.map(function(datovM){
                        
                    // console.log('Datos Obtenidos Marcav2');
                    // console.log(nuevaColeccionMarcas[i]);

                    if(nuevaColeccionMarcas[i] == "#"){
                          datovM.isview = true;
                    }else{
                          datovM.isview = false;

                          if(datovM.m_product_group_id == nuevaColeccionMarcas[i]){
                            nuevaColeccionMarcas.shift();
                            datovM.isview = boleano_marcas;
                          }else{
                            datovM.isview = false;
                          }    

                    }
                

          // console.log(datovM);
          return datovM;
        });

      // console.log('TERMINA MODIFICACION ARRAY');

       }

    },

    async recuperarIntensidadAtributo(){

      let memoriaCache = JSON.parse( localStorage.getItem('datos') );

      let coleccionIntensidades = this.atttibutes.intencidades;
      let boleano_esencias = true;

      // console.log('GENERAR ARRAYS');
      const filtroIntensidad = memoriaCache.intencidadesfiltradas
      let dato_split = filtroIntensidad.split(',') // [ 'free', 'code', 'camp' ]
      // console.log(dato_split);

      // console.log(' RECORRER ARRAYS GENERADO');
      let nuevaColeccionIntensidad = dato_split;
      for (var i=0; i<nuevaColeccionIntensidad.length; i++) {
              // console.log('Datos Obtenidosv2');
              // console.log(nuevaColeccionIntensidad[i]);

      // console.log('INICIA MODIFICACION ARRAY');
        coleccionIntensidades.map(function(datov2){
                        
                    // console.log('Datos Obtenidosv2');
                    // console.log(nuevaColeccionIntensidad[i]);

                      if(nuevaColeccionIntensidad[i] == "#"){
                        datov2.isview = true;
                      }else{
                        datov2.isview = false;
                      }

                      if(datov2.m_class_intensity_id == nuevaColeccionIntensidad[i]){
                        nuevaColeccionIntensidad.shift();
                        datov2.isview = boleano_esencias;
                      }else{
                        datov2.isview = false;
                      }

          // console.log(datov2);
          return datov2;
        });
      // console.log('TERMINA MODIFICACION ARRAY');

       }       
    },

    async recuperarCategoriaAtributo(){

      let memoriaCache = JSON.parse( localStorage.getItem('datos') );

      let coleccionCategoria = this.atttibutes.categorias;
      let boleano_categoria = true;

      // console.log('GENERAR ARRAYS CATEGORIA');
      const filtroCategoria = memoriaCache.categorias_group;
      let dato_split = filtroCategoria.split(',') // [ 'free', 'code', 'camp' ]
      // console.log(dato_split);

      // console.log(' RECORRER ARRAYS CATEGORIA');
      let nuevaColeccionCategoria = dato_split;
      // for (var i=0; i<nuevaColeccionIntensidad.length; i++) {
      //         console.log('Datos Obtenidos Intendasdasdasdsidad');
      //         console.log(nuevaColeccionIntensidad[i]);
      //  }

      // console.log('INICIA MODIFICACION CATEGORIA');
        coleccionCategoria.map(function(dato){
                        
            for (var i=0; i<nuevaColeccionCategoria.length; i++) {
                    // console.log('Datos Obtenidos Categoria');
                    // console.log(nuevaColeccionCategoria[i]);

                    if(nuevaColeccionCategoria[i] == "#"){
                          dato.isview = true;
                    }else{
                          dato.isview = false;

                          if(dato.categorias_group == nuevaColeccionCategoria[i]){
                            dato.isview = boleano_categoria;
                            nuevaColeccionCategoria.shift();

                          }else{
                            dato.isview = false;
                          }

                    }

            }
          // console.log(dato);
          return dato;
        });

      // console.log('TERMINA MODIFICACION ARRAY CATEGORIA');
       
    },


      async recuperarSubCategoriaAtributo(){

      let memoriaCache = JSON.parse( localStorage.getItem('datos') );

      let coleccionSubCategoria = this.filtrosSubcategoria;
      let boleano_subcategoria = true;

      // console.log('GENERAR ARRAYS SUBCATEGORIA');
      const filtroSubCategoria = memoriaCache.sub_categorias_group;
      let dato_split = filtroSubCategoria.split(',') // [ 'free', 'code', 'camp' ]
      // console.log(dato_split);

      // console.log(' RECORRER ARRAYS GENERADO SUBCATEGORIA');
      let nuevaColeccionSubCategoria = dato_split;
      // for (var i=0; i<nuevaColeccionIntensidad.length; i++) {
      //         console.log('Datos Obtenidos Intendasdasdasdsidad');
      //         console.log(nuevaColeccionIntensidad[i]);
      //  }

      // console.log('INICIA MODIFICACION ARRAY SUBCATEGORIA');
        coleccionSubCategoria.map(function(dato){
                        
            for (var i=0; i<nuevaColeccionSubCategoria.length; i++) {
                    // console.log('Datos Obtenidos SubCategoria');
                    // console.log(nuevaColeccionSubCategoria[i]);

                    if(nuevaColeccionSubCategoria[i] == "#"){
                          dato.isview = true;

                    }else{
                          dato.isview = false;

                          if(dato.sub_categorias_group == nuevaColeccionSubCategoria[i]){
                            dato.isview = boleano_subcategoria;
                            nuevaColeccionSubCategoria.shift();
                          }else{
                            dato.isview = false;
                          }

                    }

            }
          // console.log(dato);
          return dato;
        });

      // console.log('TERMINA MODIFICACION ARRAY SUBCATEGORIA');
       
    },

    async allProductv2(){

        // Se parsea para poder ser usado en js con JSON.parse :)
        var array = JSON.parse( localStorage.getItem('datos') );

        this.filter = array.filter;
        this.onlystock = array.onliStock;
        //this.range = array.range;

        this.ordenMenorP = array.ordenMenorP;
        this.ordenMayorP = array.ordenMayorP;
        this.ordenMasVendido = array.ordenMasVendido;


      this.productos = [];
      this.productosPaginator = [];

      let empresasValidas = array.marcasfiltradas;
      let intencidadesValidas = array.intencidadesfiltradas;

      let categorias_group_v =  array.categorias_group;
      let sub_categorias_group_v = array.sub_categorias_group;

      // let escencia_group = this.agrupacion_isview(this.escenciaCollection,'m_class_essences_id',true);

      // console.log("A2");
      // console.log(intencidadesValidas);

      let uri = config.apiAmoresens + "/productos/all";

      const paramsv2 = {
            filter: this.filter
            ,onliStock : this.onlystock
            //,range : this.range
            ,marcasfiltradas : empresasValidas
            ,ordenMenorP : this.ordenMenorP
            ,ordenMayorP : this.ordenMayorP
            ,ordenMasVendido : this.ordenMasVendido  
            ,intencidadesfiltradas : intencidadesValidas
            ,categorias_group : categorias_group_v
            ,sub_categorias_group : sub_categorias_group_v
            //,escencia_group : escencia_group
        };

      this.productos = await axios.get(uri
      ,{
        headers: { 'token': this.$cookie.get('token') },
        params: paramsv2
      
      }).then(function (response) { 
        if (response.data.status == "success") {
          // console.log(response.data);
          return response.data.data;
        } else {
          // console.log(response.data.data);
          return [];
        }
      }).catch(function (response){ 
        console.log(response);
        return [];
      });
      // this.calificacion(this.productos)
      this.lengthPaginator = Math.ceil(this.productos.length/this.totalPage); 
      this.lengthPaginator2 = Math.ceil(this.escenciaCollection.length/this.totalPage2);
    }


    ,async paginator(){
      this.lengthPaginator = Math.ceil(this.productos.length/this.totalPage);
      this.isLoad = true; 
      this.productosPaginator = [];
      let fin = ((this.page * (this.totalPage )) -1) > (this.productos.length -1) ? (this.productos.length -1):((this.page * (this.totalPage )) -1);  
    
      for (let index = ( ( (this.page -1) * this.totalPage)); index <= fin; index++) {
        let element = this.productos[index];    
        this.productosPaginator.push(element);
        if (index == fin) {
          this.isLoad = false;
          window.scrollTo(0,0);
        }
      } 
      window.scrollTo(0,0);
    },
    async paginatorClasificacion(){
      this.lengthPaginator2 = Math.ceil(this.escenciaCollection.length/this.totalPage2);
      this.isLoad = true; 
      this.escenciaPaginator = [];
      let fin = ((this.page * (this.totalPage2 )) -1) > (this.escenciaCollection.length -1) ? (this.escenciaCollection.length -1):((this.page * (this.totalPage2 )) -1);  
    
      for (let index = ( ( (this.page -1) * this.totalPage2)); index <= fin; index++) {
        let element = this.escenciaCollection[index];    
        this.escenciaPaginator.push(element);
        if (index == fin) {
          this.isLoad = false;
          window.scrollTo(0,0);
        }
      } 
      window.scrollTo(0,0);
    },

    formatMXN(value) {
      var formatter = new Intl.NumberFormat('en-ES', {style: 'currency', currency: 'USD',});
      // return formatter.format(value);
      return `${formatter.format(value)} MXN`;
    },seeProduct(value){   
      console.log(value);
      // this.$router.push(`/shop/Product/${value}/home/${this.page}`);
    },
    //  
    menu(path){
      if (this.$route.path !== path){
        this.$router.push(path);
      }  
    },
    regresar(){
      this.menu('/shop/Home');
      window.location.reload();
    },
    paginaPrincipal(){
      this.menu('/shop/HomeProductos');

    },
    async clasificacionGenerales(){
      await axios.get(config.apiAmoresens  + '/productos/clasificacionGeneral')
      .then(res=>{
        this.classGeneral = res.data.data;
      }).catch(err=>{
        console.log(err);
      })
      // if(this.classGeneral.status == 'success'){
      //   // this.classGeneral = [];
      //   this.classGeneral = this.classGeneral.data;
      // }
      // else {
      // }
    },
    async ProductosNuevos(){ //Productos nuevos
      await axios.get(config.apiAmoresens + '/productos/ProductosNuevos')
      .then(respuesta=>{
        this.productoNuevo = respuesta.data.data;
      }).catch(err=>{return err;})
    },
    async regimenTribu(){
      await axios.get(config.apiAmoresens + '/preregistro/traerRegimen')
      .then(res=>{
          this.regimenTributario = res.data.data;
          // console.log(this.regimenTributario );
      }).catch(err=>{return err})
      
    },
    async actualizarRegimen(usuario){
      let valid = this.$refs.actualizarRT.validate();
      if(valid){
        let array = {};
        let result = {};
        array.usuario = usuario;
        array.c_taxgroup_id = this.nuevoRegimen;

          await axios.put(config.apiAmoresens + '/user/actualizarRegimen',array,
          {headers: { 'token': this.$cookie.get('token') }})
          .then(res=>{
            result = res
          })
          .catch(err=>{console.log(err)})
          if(result.status == 200){
            result = result.data
            this.msg = result.message + " ¡ GRACIAS !...";
            setTimeout(() => (this.actualizar = false, this.msg =''),3000);
          }else{
            this.msg = 'LO SENTIMOS OCURRIÓ UN ERROR, VULEVE MÁS TARDE';

          }

          
        
      }


    }

  },
  computed:{ 
    //>>>>>>>>> Filtador
    filtrador() {
      return this.escenciaPaginator.filter(post => {
        return post.value.toLowerCase().includes(this.buscar.toLowerCase())
      })
    }
  },  
  
}
</script>
